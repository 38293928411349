import axios from 'axios';
import { useRouter } from 'next/router';
import type { FC } from 'react';
import { useEffect, useState } from 'react';
import Button from '@b2b-frontend/components/Button';
import Heading from '@b2b-frontend/components/Heading';
import TextInput from '@b2b-frontend/components/TextInput';
import Link from '@b2b-frontend/components/Link';
import { useLocale } from '@b2b-frontend/utils/sites';
import { LOCALE } from '@b2b-frontend/constants';
import { ROUTES } from '../../constants';
import MaxWidthContainer from 'components/MaxWidthContainer';
import useUser from 'utils/useUser';

const Login: FC = () => {
	const locale = useLocale();
	const { push } = useRouter();
	const { isLoggedIn } = useUser();
	const [email, setEmail] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isSubmitted, setIsSubmitted] = useState(false);

	useEffect(() => {
		if (isLoggedIn) {
			push('/');
		}
	}, [isLoggedIn, push]);

	const handleRegisterSubmit = async event => {
		event.preventDefault();
		setIsSubmitting(true);

		try {
			await axios.post('/api/register', {
				email,
			});
			setIsSubmitted(true);
		} catch (e) {}

		setIsSubmitting(false);
	};

	const emailContactAddress =
		locale === LOCALE.AU
			? 'service@contact.lorealorders.com.au'
			: 'service@contact.lorealorders.co.nz';
	const contactPhoneNumber = locale === LOCALE.AU ? '1300 651 991' : '0800 567 325';

	return (
		<div className="bg-pattern py-12 flex-1">
			<MaxWidthContainer size="large">
				<Heading size="text-lg" className="text-center mb-2">
					Welcome to L&rsquo;Or&eacute;al Orders and ProShop!
				</Heading>
			</MaxWidthContainer>
			<MaxWidthContainer size="small">
				<p className="text-sm mb-6 text-center">
					We have updated the way you log in to improve our security and functionality.
					You will now log in using your email address and may need to set a new password
				</p>
				<div className="grid grid-cols-1 sm:grid-cols-2 mb-4 gap-4">
					<div className="p-6 bg-white flex flex-col items-stretch">
						<Heading as="h2" className="mb-6" size="text-md">
							Log in now
						</Heading>
						<ul className="list-disc pl-4 mb-4">
							<li>
								L&rsquo;Or&eacute;al Online Payment Portal customers: your
								credentials now work across both platforms.
							</li>
							<li>
								Sales Representatives: please use your L&rsquo;Or&eacute;al
								credentials.
							</li>
							<li>If you have already set a new password</li>
						</ul>
						<div className="text-center mt-auto">
							<Button href={ROUTES.API.AUTH.LOGIN} className="w-full">
								Login
							</Button>
						</div>
					</div>
					<div className="p-6 bg-white">
						{isSubmitted ? (
							<>
								<Heading as="h2" className="mb-6" size="text-md">
									Request received
								</Heading>
								<p className="mb-4">
									Thank you, your registration request has been received. If your
									email exists you will be sent a password reset email. Please
									check your email and if you haven’t received anything in 15
									minutes{' '}
									<Link href={ROUTES.HELP.CONTACT_US} className="underline">
										get in touch with us
									</Link>
									.
								</p>
								<p>
									Entered the wrong email?{' '}
									<button
										type="button"
										onClick={() => {
											setIsSubmitted(false);
											setEmail('');
										}}
										className="underline"
									>
										Enter a new one here
									</button>
									.
								</p>
							</>
						) : (
							<>
								<Heading as="h2" className="mb-6" size="text-md">
									Update credentials
								</Heading>
								<p className="mb-4">
									If this is your first time using the new log in, please use your
									L&rsquo;Or&eacute;al account email address to set a new password
								</p>
								<form
									className="text-center flex flex-col"
									onSubmit={handleRegisterSubmit}
								>
									<TextInput
										id="email"
										name="email"
										label="Email address"
										value={email}
										onChange={setEmail}
										type="email"
									/>
									<Button
										className="mt-4"
										type="submit"
										isSubmitting={isSubmitting}
									>
										Set New Password
									</Button>
								</form>
							</>
						)}
					</div>
				</div>
				<p className="text-sm mb-2 text-center">
					An email address linked to your L&rsquo;Or&eacute;al Customer Account is
					required for access.
				</p>
				<p className="text-sm mb-2 text-center">
					If you are having issues with your account, please contact us on{' '}
					<Link
						href={`tel:${contactPhoneNumber.replace(/\s/g, '')}`}
						className="underline hover:no-underline"
					>
						{contactPhoneNumber}
					</Link>{' '}
					B2B (option 7) or{' '}
					<Link
						href={`mailto:${emailContactAddress}`}
						className="underline hover:no-underline"
					>
						{emailContactAddress}
					</Link>
				</p>

				<p className="text-sm mb-2 text-center">
					If you are not a L&rsquo;Or&eacute;al Customer, please{' '}
					<Link href="/help/contact-us" className="underline hover:no-underline">
						contact us
					</Link>{' '}
					in order to get set up
				</p>
			</MaxWidthContainer>
		</div>
	);
};

export default Login;
