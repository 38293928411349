import { PAGE_CATEGORY } from '@b2b-frontend/utils/analytics/constants';
import Login from '../views/Login';

export default Login;

export const getServerSideProps = async () => {
	return {
		props: {
			dataLayerSeed: {
				pageCategory: PAGE_CATEGORY.ACCOUNT_MANAGEMENT,
			},
		},
	};
};
