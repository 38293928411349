import React from 'react';
import clsx from 'clsx';

interface ITextInputProps {
	className?: string;
	id: string;
	name: string;
	label: string;
	type?: 'text' | 'email' | 'number' | 'tel' | 'search';
	value: string;
	placeholder?: string;
	onChange: (newValue: string) => void;
	isError?: boolean;
	errorMessage?: string;
	helpText?: string;
	[key: string]: any;
}

const TextInput: React.FC<ITextInputProps> = ({
	className = '',
	id,
	label,
	onChange,
	name,
	value,
	placeholder = ' ',
	type = 'text',
	isError = false,
	errorMessage = '',
	helpText,
	...inputProps
}) => {
	const descriptionId = `${id}_description`;

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;

		onChange(newValue);
	};

	return (
		<div>
			<div className={clsx('relative', className)}>
				<input
					{...inputProps}
					type={type}
					id={id}
					name={name}
					value={value}
					onChange={handleChange}
					aria-describedby={helpText ? descriptionId : undefined}
					aria-invalid={isError}
					className={clsx(
						'text-xs w-full peer px-0 pt-5 pb-2 placeholder-transparent border-b border-0 border-gray focus:border-brand focus:outline-none active:outline-none focus:shadow-none focus:ring-0',
						{
							'border-red-500': isError,
						},
					)}
					placeholder={placeholder}
				/>
				<label
					htmlFor={id}
					className="top-0 text-xs text-gray-dark peer-placeholder-shown:top-6 absolute left-0 peer-focus:text-black peer-focus:top-0 transition-all"
				>
					{label}
				</label>
			</div>
			{helpText && (
				<p className="mt-2 text-xs text-gray-dark" id={descriptionId}>
					{helpText}
				</p>
			)}
			{isError && <span className="mt-1 text-xs text-red-500">{errorMessage}</span>}
		</div>
	);
};

export default TextInput;
